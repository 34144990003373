import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardActions, Button } from "@material-ui/core";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import group from "../assets/images/teamwork.png";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BlockIcon from "@material-ui/icons/Block";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
const useStyles = makeStyles({
  root: {
    maxWidth: 200,
    minWidth: 260,
  },
  media: {
    height: 90,
    width: 90,
    //borderRadius: 60,

    marginTop: 8,
  },
});

const GroupItem = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      {/* <CardActionArea> */}
      <div style={{ justifyContent: "center", display: "flex" }}>
        <div>
          <CardMedia
            className={classes.media}
            image={
              props.group.imageUrl === "" ||
              props.group.imageUrl === null ||
              props.group.imageUrl === undefined
                ? group
                : props.group.imageUrl
            }
            title="Contemplative"
          />
        </div>
      </div>
      <CardContent>
        <Typography
          style={{
            textAlign: "center",
          }}
          gutterBottom
          variant="body1"
          color="textSecondary"
          component="p"
        >
        {props.group.name}
        </Typography>
        <Typography
          style={{
            textAlign: "center",
          }}
          variant="body2"
          color="textSecondary"
          component="p"
        >
           Class: {props.group.standard.name}
        </Typography>
      </CardContent>
      {/* </CardActionArea> */}
      <CardActions style={{ justifyContent: "center" }}>
        {/* <div>{props.group.isActive ? <CheckCircleIcon /> : <BlockIcon />}</div> */}
        <Button size="small" color="primary" onClick={props.onClick}>
          Group Details...
          {/* <ArrowRightAltIcon /> */}
        </Button>
      </CardActions>
    </Card>
  );
};
export default GroupItem;
