import React, { useEffect, useState } from "react";
import Drawer from "../components/Drawer";

import AccountItem from "../components/AccountItem";
import Breadcrumbs from "../components/Breadcrumbs";
import { getAccountDetail } from "../store/actions/Account";
import { useSelector, useDispatch } from "react-redux";
import PasswordTextField from "../components/PasswordTextField";
import { changePassword } from "../store/actions/Account";
import {
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AppBar from '../components/AppBar'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const Account = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account.account);
  const user = useSelector((state) => state.auth.user);
  const [oldPassword, setoldPassword] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");

  const [oldPasswordError, setoldPasswordError] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [confirmPasswordError, setconfirmPasswordError] = useState("");
  const message = useSelector((state) => state.auth.message);
  const error = useSelector((state) => state.auth.error);

  //console.log("account" + account);
  useEffect(() => {
    dispatch(getAccountDetail());
  }, [dispatch]);

  const handleOldPasswordChange = (e) => {
    e.preventDefault();
    setoldPassword(e.target.value);
    setoldPasswordError("");
  };
  const handlePasswordChange = (e) => {
    e.preventDefault();
    setpassword(e.target.value);
    setpasswordError("");
  };
  const handleConfirmPasswordChange = (e) => {
    e.preventDefault();
    setconfirmPassword(e.target.value);
    setconfirmPasswordError("");
  };

  const validate = () => {
    if (oldPassword == "") {
      setoldPasswordError("Old Password is Empty");
      return false;
    }
    if (password == "") {
      setpasswordError("Password is Empty");
      return false;
    }
    if (confirmPassword == "") {
      setconfirmPasswordError("Confirm Password is Empty");
      return false;
    }
    if (password.length < 8) {
      setpasswordError("Password Shoud have at atleast 8 characters");
      return false;
    }

    if (password !== confirmPassword) {
      setpasswordError("Passwords do not match");
      setconfirmPasswordError("Passwords do not match");
      return false;
    }
    return true;
  };
  const onClickChangePassword = () => {
    const isValid = validate();
    if (isValid) {
      dispatch(
        changePassword({
          id: user.id,
          oldPassword,
          password,
          confirmPassword,
        })
      );
    }
  };
  return (
    <div>
       <AppBar />
            <div style={{ padding: 16 }}>
        <Breadcrumbs />
        <div
          style={{
            //alignContent: "center",
            //alignItems: "center",
            justifyContent: "center",
            // alignSelf: "center",
            // display: "flex",
          }}
        >
          <div>
            {account && <AccountItem account={account} />}
            {/* <Accordion style={{ maxWidth: 800 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Change Password
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <div style={{ width: 400 }}>
                    {error && (
                      <Alert severity="error">
                        <AlertTitle>{error}</AlertTitle>
                      </Alert>
                    )}
                    {message && <Alert severity="success">{message}</Alert>}
                  </div>
                  <PasswordTextField
                    label="Old Password"
                    value={oldPassword}
                    onChange={handleOldPasswordChange}
                    error={oldPasswordError}
                  />
                  <PasswordTextField
                    label="Password"
                    value={password}
                    onChange={handlePasswordChange}
                    error={passwordError}
                  />
                  <PasswordTextField
                    label="Confirm Password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    error={confirmPasswordError}
                  />
                  <div style={{ justifyContent: "space-between", margin: 8 }}>
                    <Button
                      onClick={onClickChangePassword}
                      variant="contained"
                      color="primary"
                    >
                      Change
                    </Button>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
