import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import { setAlert } from './Alert'
export const CREATE_STUDENT_REQUEST = "CREATE_STUDENT_REQUEST";
export const CREATE_STUDENT_FAILURE = "CREATE_STUDENT_FAILURE";
export const CREATE_STUDENT_SUCCESS = "CREATE_STUDENT_SUCCESS";

export const CREATE_MULTIPLE_STUDENTS_REQUEST =
  "CREATE_MULTIPLE_STUDENTS_REQUEST";
export const CREATE_MULTIPLE_STUDENTS_FAILURE =
  "CREATE_MULTIPLE_STUDENTS_FAILURE";
export const CREATE_MULTIPLE_STUDENTS_SUCCESS =
  "CREATE_MULTIPLE_STUDENTS_SUCCESS";

export const EDIT_STUDENT_REQUEST = "EDIT_STUDENT_REQUEST";
export const EDIT_STUDENT_FAILURE = "EDIT_STUDENT_FAILURE";
export const EDIT_STUDENT_SUCCESS = "EDIT_STUDENT_SUCCESS";

export const GET_GROUP_STUDENTS_REQUEST = "GET_GROUP_STUDENTS_REQUEST";
export const GET_GROUP_STUDENTS_FAILURE = "GET_GROUP_STUDENTS_FAILURE";
export const GET_GROUP_STUDENTS_SUCCESS = "GET_GROUP_STUDENTS_SUCCESS";
export const GET_STUDENT_DETAIL_REQUEST = "GET_STUDENT_DETAIL_REQUEST";
export const GET_STUDENT_DETAIL_FAILURE = "GET_STUDENT_DETAIL_FAILURE";
export const GET_STUDENT_DETAIL_SUCCESS = "GET_STUDENT_DETAIL_SUCCESS";
export const GET_STUDENT_BY_EMAIL_REQUEST = "GET_STUDENT_BY_EMAIL_REQUEST";
export const GET_STUDENT_BY_EMAIL_FAILURE = "GET_STUDENT_BY_EMAIL_FAILURE";
export const GET_STUDENT_BY_EMAIL_SUCCESS = "GET_STUDENT_BY_EMAIL_SUCCESS";

export const GET_PARENT_BY_EMAIL_REQUEST = "GET_PARENT_BY_EMAIL_REQUEST";
export const GET_PARENT_BY_EMAIL_FAILURE = "GET_PARENT_BY_EMAIL_FAILURE";
export const GET_PARENT_BY_EMAIL_SUCCESS = "GET_PARENT_BY_EMAIL_SUCCESS";

export const RESET_STUDENT_REQUEST = "RESET_STUDENT_REQUEST";
export const RESET_STUDENT_FAILURE = "RESET_STUDENT_FAILURE";
export const RESET_STUDENT_SUCCESS = "RESET_STUDENT_SUCCESS";

export const RESET_PARENT_REQUEST = "RESET_PARENT_REQUEST";
export const RESET_PARENT_FAILURE = "RESET_PARENT_FAILURE";
export const RESET_PARENT_SUCCESS = "RESET_PARENT_SUCCESS";
const timeout = 8000;
export const createStudent = (userData) => async (dispatch) => {
  const { name, email, phoneNumber, groupId, instituteId, password } = userData;
  dispatch({
    type: CREATE_STUDENT_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        name,
        email,
        phoneNumber,
        groupId,
        instituteId,
        password,
      });
      console.log("body " + body);

      const res = await axios.post(baseUrl + "/api/student/add", body, config);
      console.log("res" + res);
      if (res.status === 200) {
        dispatch({
          type: CREATE_STUDENT_SUCCESS,
          payload: res.data.message,
        });
        dispatch(setAlert({ message: res.data.message, alertType: 'success' }))
      } else {
        dispatch({
          type: CREATE_STUDENT_FAILURE,
          payload: res.data.message.message,
        });
      }
    } catch (err) {
      dispatch(setAlert({ message: err.response.data.messages[0].msg, alertType: 'error' }))
      dispatch({
        type: CREATE_STUDENT_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const createMultipleStudents = (userData) => async (dispatch) => {
  const { students, groupId, instituteId, password } = userData;
  dispatch({
    type: CREATE_MULTIPLE_STUDENTS_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        students,
        groupId,
        instituteId,
        password,
      });
      console.log("body " + body);

      const res = await axios.post(
        baseUrl + "/api/student/add-multiple",
        body,
        config
      );
      console.log("res" + JSON.stringify(res));
      if (res.status === 200) {
        dispatch({
          type: CREATE_MULTIPLE_STUDENTS_SUCCESS,
          payload: res.data.message,
        });
        dispatch(setAlert({ message: res.data.message, alertType: 'success' }))
      } else {
        dispatch({
          type: CREATE_MULTIPLE_STUDENTS_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: CREATE_MULTIPLE_STUDENTS_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getStudentById = (userId) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_STUDENT_DETAIL_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(`${baseUrl}/student/${userId}`, config);
      //      console.log(res);

      if (res.status === 200) {
        dispatch({
          type: GET_STUDENT_DETAIL_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_STUDENT_DETAIL_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      console.log("get student by id fail");

      dispatch({
        type: GET_STUDENT_DETAIL_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getStudentByEmail = ({ email }) => async (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_STUDENT_BY_EMAIL_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const body = JSON.stringify({
        email
      });
      const res = await axios.post(`${baseUrl}/api/student/detail`, body, config);
      console.log(res);
      if (res.status === 200) {
        if (res.data.status == 'error') {
          dispatch(setAlert({ message: res.data.messages[0].msg, alertType: 'error' }))
          dispatch({
            type: GET_STUDENT_BY_EMAIL_FAILURE,
            payload: res.data.message,
          });
          return
        }
        dispatch({
          type: GET_STUDENT_BY_EMAIL_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch(setAlert({ message: res.data.messages[0].msg, alertType: 'error' }))
        dispatch({
          type: GET_STUDENT_BY_EMAIL_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      clearTimeout(id);
      dispatch({
        type: GET_STUDENT_BY_EMAIL_FAILURE,
        payload: err,
      });
    }
  }
};

export const resetStudentPassword = ({ email }) => async (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const token = localStorage.getItem("token");
  dispatch({
    type: RESET_STUDENT_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const body = JSON.stringify({
        email
      });
      const res = await axios.post(`${baseUrl}/api/student/reset-password`, body, config);
      console.log(res);
      if (res.status === 200) {
        if (res.data.status == 'error') {
          dispatch(setAlert({ message: res.data.messages[0].msg, alertType: 'error' }))
          dispatch({
            type: RESET_STUDENT_FAILURE,
            payload: res.data.message,
          });
          return
        }
        dispatch(setAlert({ message: res.data.messages[0].msg, alertType: 'success' }))

        dispatch({
          type: RESET_STUDENT_SUCCESS,
          payload: res.data,
        });
      }
      else {
        dispatch(setAlert({ message: res.data.messages[0].msg, alertType: 'error' }))
        dispatch({
          type: RESET_STUDENT_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      clearTimeout(id);
      dispatch({
        type: RESET_STUDENT_FAILURE,
        payload: err,
      });
    }
  }
};
export const getParentByEmail = ({ email }) => async (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_PARENT_BY_EMAIL_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const body = JSON.stringify({
        email
      });
      const res = await axios.post(`${baseUrl}/api/parent/detail`, body, config);
      console.log(res);
      if (res.status === 200) {
        if (res.data.status == 'error') {
          dispatch(setAlert({ message: res.data.messages[0].msg, alertType: 'error' }))
          dispatch({
            type: GET_PARENT_BY_EMAIL_FAILURE,
            payload: res.data.message,
          });
          return
        }
        dispatch({
          type: GET_PARENT_BY_EMAIL_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch(setAlert({ message: res.data.messages[0].msg, alertType: 'error' }))
        dispatch({
          type: GET_PARENT_BY_EMAIL_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      clearTimeout(id);
      dispatch({
        type: GET_PARENT_BY_EMAIL_FAILURE,
        payload: err,
      });
    }
  }
};

export const resetParentPassword = ({ email }) => async (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const token = localStorage.getItem("token");
  dispatch({
    type: RESET_PARENT_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const body = JSON.stringify({
        email
      });
      const res = await axios.post(`${baseUrl}/api/student/reset-password`, body, config);
      console.log(res);
      if (res.status === 200) {
        if (res.data.status == 'error') {
          dispatch(setAlert({ message: res.data.messages[0].msg, alertType: 'error' }))
          dispatch({
            type: RESET_PARENT_FAILURE,
            payload: res.data.message,
          });
          return
        }
        dispatch({
          type: RESET_PARENT_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch(setAlert({ message: res.data.messages[0].msg, alertType: 'error' }))
        dispatch({
          type: RESET_PARENT_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      clearTimeout(id);
      dispatch({
        type: RESET_PARENT_FAILURE,
        payload: err,
      });
    }
  }
};
export const getGroupStudents = (groupId) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_GROUP_STUDENTS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/student/group/${groupId}`,
        config
      );
      //console.log(res.data);

      if (res.status === 200) {
        let stds = [];
        res.data.students.forEach((tchr) => {
          let t = {
            ...tchr,
            id: tchr._id,
          };
          stds.push(t);
        });
        dispatch({
          type: GET_GROUP_STUDENTS_SUCCESS,
          payload: stds,
        });
      } else {
        dispatch({
          type: GET_GROUP_STUDENTS_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      console.log("get students failure");

      dispatch({
        type: GET_GROUP_STUDENTS_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};
export const editStudent = (id, userData) => async (dispatch) => {
  const { name, email, phoneNumber } = userData;
  dispatch({
    type: EDIT_STUDENT_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        id,
        name,
        email,
        phoneNumber,
      });

      const res = await axios.put(`${baseUrl}/student/`, body, config);
      if (res.status === 200) {
        dispatch({
          type: EDIT_STUDENT_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: EDIT_STUDENT_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_STUDENT_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};
