import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
export const GET_ACCOUNT_DETAIL_REQUEST = "GET_ACCOUNT_DETAIL_REQUEST";
export const GET_ACCOUNT_DETAIL_FAILURE = "GET_ACCOUNT_DETAIL_FAILURE";
export const GET_ACCOUNT_DETAIL_SUCCESS = "GET_ACCOUNT_DETAIL_SUCCESS";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const VERIFY_ACCOUNT_REQUEST = "VERIFY_ACCOUNT_REQUEST";
export const VERIFY_ACCOUNT_SUCCESS = "VERIFY_ACCOUNT_SUCCESS";
export const VERIFY_ACCOUNT_FAILURE = "VERIFY_ACCOUNT_FAILURE";

export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAILURE = "SET_PASSWORD_FAILURE";

export const FORGOT_PASSWORD_REQUEST_REQUEST =
  "FORGOT_PASSWORD_REQUEST_REQUEST";
export const FORGOT_PASSWORD_REQUEST_SUCCESS =
  "FORGOT_PASSWORD_REQUEST_SUCCESS";
export const FORGOT_PASSWORD_REQUEST_FAILURE =
  "FORGOT_PASSWORD_REQUEST_FAILURE";

export const getAccountDetail = () => async (dispatch) => {
  console.log("account request");
  dispatch({
    type: GET_ACCOUNT_DETAIL_REQUEST,
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  try {
    const res = await axios.get(`${baseUrl}/api/support-user/account`, config);
    console.log(res);

    if (res.status == 200) {
      dispatch({
        type: GET_ACCOUNT_DETAIL_SUCCESS,
        payload: res.data.impruUser,
      });
    } else {
      console.log('falied ');
      dispatch({
        type: GET_ACCOUNT_DETAIL_FAILURE,
        payload: res.data.message,
      });
    }
  } catch (err) {
    console.log("get account by id fail" + err);

    dispatch({
      type: GET_ACCOUNT_DETAIL_FAILURE,
      payload: err.res,
    });
  }
};

// Set change password
export const changePassword = ({
  id,
  oldPassword,
  password,
  confirmPassword,
}) => (dispatch) => {
  dispatch({
    type: CHANGE_PASSWORD_REQUEST,
  });
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({
    id: id,
    oldPassword: oldPassword,
    password: password,
    confirmPassword: confirmPassword,
  });

  axios
    .post(`${baseUrl}/api/support-user/change-password`, body, config)
    .then((res) => {
      console.log("change p" + JSON.stringify(res));
      if (res.status === 200) {
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS,
          payload: res.data.message,
        });
      } else {
        dispatch({
          type: CHANGE_PASSWORD_FAILURE,
          payload: res.data.message,
        });
      }
    })
    .catch((err) => {
      console.log(err.response.data.message);
      dispatch({
        type: CHANGE_PASSWORD_FAILURE,
        payload: err.response.data.message,
      });
    });
};

export const forgotPasswordRequest = (email) => (dispatch) => {
  dispatch({
    type: FORGOT_PASSWORD_REQUEST_REQUEST,
  });
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({
    email,
  });
  axios
    .post(baseUrl + "/api/support-user/forgot-password", body, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: FORGOT_PASSWORD_REQUEST_SUCCESS,
          payload: res.data.message,
        });
      } else {
        dispatch({
          type: FORGOT_PASSWORD_REQUEST_FAILURE,
          payload: res.data.message,
        });
      }
    })
    .catch((err) =>
      dispatch({
        type: FORGOT_PASSWORD_REQUEST_FAILURE,
        payload: err.response.data.message,
      })
    );
};

// Set change password

export const verifyAccount = ({ email, code, password, confirmPassword }) => (
  dispatch
) => {
  dispatch({
    type: VERIFY_ACCOUNT_REQUEST,
  });
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({
    email,
    password,
    confirmPassword,
    code,
  });
  axios
    .post(baseUrl + "/api/support-user/verify-account", body, config)
    .then((res) => {
      if (res.status === 200) {
        console.log(res);
        dispatch({
          type: VERIFY_ACCOUNT_SUCCESS,
          payload: res.data.message,
        });
      } else {
        dispatch({
          type: VERIFY_ACCOUNT_FAILURE,
          payload: res.data.message,
        });
      }
    })
    .catch((err) =>
      dispatch({
        type: CHANGE_PASSWORD_FAILURE,
        payload: err.response.data.message,
      })
    );
};

export const setAccountPassword = ({
  email,
  code,
  password,
  confirmPassword,
}) => (dispatch) => {
  dispatch({
    type: SET_PASSWORD_REQUEST,
  });
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({
    email,
    password,
    confirmPassword,
    code,
  });
  console.log(body);
  axios
    .post(baseUrl + "/api/support-user/set/support-user", body, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: SET_PASSWORD_SUCCESS,
          message: res.data.message,
        });
      } else {
        dispatch({
          type: SET_PASSWORD_FAILURE,
          payload: res.data.message,
        });
      }
    })
    .catch((err) =>
      dispatch({
        type: SET_PASSWORD_FAILURE,
        payload: err.response.data.message,
      })
    );
};
