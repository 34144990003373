import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  
  Card,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import InstituteDetailListItem from "./InstituteDetailListItem";
import image from "../assets/images/institute.jpg";
const useStyles = makeStyles({
  root: {
    maxWidth: 800,
  },
  media: {
    height: 140,
    width: 140,
    marginTop: 8,
    borderRadius: 70,
  },
});
const OrganizationDetail = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div style={{ justifyContent: "center", display: "flex" }}>
        <CardMedia
          className={classes.media}
          image={image}
          title="Contemplative Reptile"
        />
      </div>
      <CardContent>
        <Typography
          style={{
            textAlign: "center",
          }}
          gutterBottom
          variant="h5"
          component="h2"
        >
          {props.institute.name && props.institute.name}
        </Typography>
        <Divider />
        <List>
          <ListItem selected={true}>
            <ListItemText primary="Details" />
          </ListItem>
        </List>
        <InstituteDetailListItem
          answer={
            props.institute.instituteNumber && props.institute.instituteNumber
          }
          title="Institute Number"
        />
        <InstituteDetailListItem
          answer={
            props.institute.instituteMode && props.institute.instituteMode
          }
          title="Institute Mode"
        />
        <InstituteDetailListItem
          answer={
            props.institute.instituteType && props.institute.instituteType
          }
          title="Institute Category"
        />
        <InstituteDetailListItem
          answer={props.institute.email && props.institute.email}
          title="Eamil Id"
        />
        <InstituteDetailListItem
          answer={props.institute.phoneNumber && props.institute.phoneNumber}
          title="Phone Number "
        />
        {/* <Divider />

        <List>
          <ListItem selected={true}>
            <ListItemText primary="Academic Details" />
          </ListItem>
        </List>
        <InstituteDetailListItem
          answer={
            props.institute.academicInfo &&
            props.institute.academicInfo.boards.map((b) => `${b},`)
          }
          title="Boards"
        />
        <InstituteDetailListItem
          answer={
            props.institute.academicInfo &&
            props.institute.academicInfo.classes.map((cl) => `${cl},`)
          }
          title="Classes Offered"
        />
        <InstituteDetailListItem
          answer={
            props.institute.academicInfo &&
            props.institute.academicInfo.courses.map((crs) => `${crs},`)
          }
          title="Courses Offered"
        />
        <InstituteDetailListItem
          answer={
            props.institute.academicInfo &&
            props.institute.academicInfo.teachers
          }
          title="Teachers Strength"
        />
        <InstituteDetailListItem
          answer={
            props.institute.academicInfo &&
            props.institute.academicInfo.students
          }
          title="Students Strenth "
        /> */}
        <Divider />
        <List>
          <ListItem selected={true}>
            <ListItemText primary="Address" />
          </ListItem>
        </List>
        <InstituteDetailListItem
          answer={props.institute.address && props.institute.address.street}
          title="Street"
        />
        <InstituteDetailListItem
          answer={props.institute.address && props.institute.address.area}
          title="Area"
        />
        <InstituteDetailListItem
          answer={props.institute.address && props.institute.address.city}
          title="City"
        />
        <InstituteDetailListItem
          answer={props.institute.address && props.institute.address.taluk}
          title="Taluk"
        />
        <InstituteDetailListItem
          answer={props.institute.address && props.institute.address.district}
          title="District "
        />
        <InstituteDetailListItem
          answer={props.institute.address && props.institute.address.state}
          title="State "
        />
        <InstituteDetailListItem
          answer={props.institute.address && props.institute.address.pincode}
          title="Pincode "
        />
      </CardContent>
    </Card>
  );
};
export default OrganizationDetail;
