import React from "react";
import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import moment from 'moment';

const DateListItem = (props) => {
    return (
        <div>
            <div
                style={{
                    justifyContent: "space-between",
                    flexDirection: "row",
                    display: "flex",
                }}
            >
                <Typography style={{ minWidth: 360 }}>{props.title} </Typography>
                <Typography style={{ marginLeft: 16 }}>
                    {props.answer?moment(props.answer).format("LLL"):'NO DATA'}
                </Typography>
            </div>
        </div>
    );
};

export default DateListItem;
