import React, { useEffect } from "react";
import Drawer from "../components/Drawer";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import GroupItem from "../components/GroupItem";
import Breadcrumbs from "../components/Breadcrumbs";
import { getGroupDetail } from "../store/actions/Groups";
import AppBar from '../components/AppBar'

const useStyles = makeStyles((theme) => ({
  appMain: {
    // paddingLeft: "300px",
    paddingTop: "100px",
    width: "100%",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const InstituteGroups = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const grps = useSelector((state) => state.groups.groups);
  const isLoading = useSelector((state) => state.groups.isLoading);
  useEffect(() => {
    if (!grps && isLoading === false) {
      props.history.push("/");
    }
  }, [props.history, grps, isLoading]);
  console.log("grps" + grps);
  const onClickGetGroupDetail = (groupId) => {
    dispatch(getGroupDetail(groupId));
    props.history.push("/institute-groups-detail");
  };

  return (
    <div>
      <Drawer>
        <Breadcrumbs />
        <div
          style={{
            //width: 800,
            alignItems: "center",
            justifyContent: "center",
            //alignSelf: "center",
            //alignContent: "center",
            //flex: 1,
            // display: "flex",
            //flexDirection: "column",
          }}
        >
          <div style={{ width: 800 }}>
            <Grid
              container
              direction="row"
              //justify="center"
              //alignItems="center"
              spacing={3}
            >
              {grps.length > 0 ? (
                grps.map((g, i) => (
                  <Grid item key={i.toString()}>
                    <GroupItem
                      onClick={() => {
                        onClickGetGroupDetail(g._id);
                      }}
                      group={g}
                    />
                  </Grid>
                ))
              ) : (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    width: 500,
                    height: 500,
                    backgroundColor: "whitesmoke",
                    marginTop: 16,
                    borderRadius: 8,
                    // alignItems: "center",
                  }}
                >
                  <h5
                    style={{
                      display: "flex",
                      // flex: 1,
                      justifyContent: "center",
                      //erticalAlign: "center",
                      //alignContent: "center",
                      alignItems: "center",
                      // marginTop: 300,
                    }}
                  >
                    No Groups found this insitute
                  </h5>
                </div>
              )}
            </Grid>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default InstituteGroups;
