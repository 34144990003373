import {
  CREATE_TEACHER_REQUEST,
  CREATE_TEACHER_FAILURE,
  CREATE_TEACHER_SUCCESS,
  CREATE_MULTIPLE_TEACHERS_FAILURE,
  CREATE_MULTIPLE_TEACHERS_REQUEST,
  CREATE_MULTIPLE_TEACHERS_SUCCESS,
  GET_INSTITUTE_TEACHERS_FAILURE,
  GET_INSTITUTE_TEACHERS_SUCCESS,
  GET_INSTITUTE_TEACHERS_REQUEST,
  GET_TEACHER_DETAIL_REQUEST,
  GET_TEACHER_DETAIL_FAILURE,
  GET_TEACHER_DETAIL_SUCCESS,
  GET_TEACHER_BY_EMAIL_REQUEST,
  GET_TEACHER_BY_EMAIL_SUCCESS,
  GET_TEACHER_BY_EMAIL_FAILURE,

  EDIT_TEACHER_REQUEST,
  EDIT_TEACHER_FAILURE,
  EDIT_TEACHER_SUCCESS,
  ADD_SUBJECT_TEACHER_FAILURE,
  ADD_SUBJECT_TEACHER_SUCCESS,
  ADD_SUBJECT_TEACHER_REQUEST,

  RESET_TEACHER_REQUEST,
  RESET_TEACHER_FAILURE,
  RESET_TEACHER_SUCCESS,
} from "../actions/Teachers";

const initialState = {
  teachers: [],
  teacher: null,
  error: null,
  message: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_TEACHER_REQUEST:
    case CREATE_MULTIPLE_TEACHERS_REQUEST:
    case ADD_SUBJECT_TEACHER_REQUEST:
    case RESET_TEACHER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_TEACHER_SUCCESS:
    case CREATE_MULTIPLE_TEACHERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        message: action.payload,
      };

    case CREATE_TEACHER_FAILURE:
    case CREATE_MULTIPLE_TEACHERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_INSTITUTE_TEACHERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_INSTITUTE_TEACHERS_SUCCESS:
      return {
        ...state,
        teachers: action.payload,
        isLoading: false,
        error: null,
      };
    case GET_INSTITUTE_TEACHERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_TEACHER_DETAIL_REQUEST:
    case GET_TEACHER_BY_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_TEACHER_DETAIL_SUCCESS:
    case GET_TEACHER_BY_EMAIL_SUCCESS:
      return {
        ...state,
        teacher: action.payload,
        isLoading: false,
        error: null,
      };
    case GET_TEACHER_DETAIL_FAILURE:
    case GET_TEACHER_BY_EMAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case EDIT_TEACHER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_TEACHER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case EDIT_TEACHER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ADD_SUBJECT_TEACHER_SUCCESS:
      return {
        ...state,
        error: null,
        message: action.payload,
      };
    case ADD_SUBJECT_TEACHER_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case RESET_TEACHER_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
      };
    case RESET_TEACHER_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
