import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Box,
  Typography,
  makeStyles,
  Container,
  Card,
  CardMedia
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../store/actions/Auth";
import logo from "../assets/images/impru-logo.png";
import CustomTextField from '../components/CustomTextField'
import PasswordTextField from '../components/PasswordTextField'
import AppBar from '../components/AppBar'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="/">
        impruapp.com
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // backgroundColor: "yellow",
    // paddingBottom: 80,
    paddingTop: 40,
    padding: 30,
    display: "flex",
    //flexDirection: "row",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  submit: {
    // margin: theme.spacing(3, 0, 2),
    maxWidth: 360,
    margin: 8
  },
  logo: {
    height: 100,
    width: 100,
    borderRadius: 70,

  },
}));
const SignIn = (props) => {
  const classes = useStyles();
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const messages = useSelector((state) => state.auth.messages);
  const alert = useSelector((state) => state.alert.alert);
  if (auth.isAuthenticated) {
    //return <Redirect to="/admin/dashboard" />;
    return <Redirect to="/Teacher Support" />;
  }


  const validate = () => {
    // if (email == '' && password == "") {
    //   setEmailError("Please enter the email");
    //   setPasswordError("Please enter the password");
    //   return false
    // }
    if (email == "") {
      setError("Please enter the email")
      setEmailError("Please enter the email");
      return false
    }
    if (email.length > 0) {
      if (!regexp.test(email)) {
        setEmailError("Please enter a valid email");
        setError("Please enter a valid email")
        return false;
      }
    }
    if (password == '') {
      setPasswordError("Please enter the password");
      setError("Please enter the password")
      return false
    }
    return true
  }
  const onChangeEmail = (e) => {
    setemail(e.target.value)
    setEmailError("")
    setError("")
  }

  const onChangePassword = (e) => {
    setpassword(e.target.value)
    setPasswordError("")
    setError("")
  }
  const login = () => {

    const isValid = validate()
    if (isValid) {
      dispatch(loginUser({ email, password }));

    }

  };

  return (
    <div>
      <AppBar />
      <div style={{ padding: 16 }}></div>


      <Container component="main" maxWidth="sm">
        {/* <CssBaseline /> */}
        <Card style={{ borderRadius: 0 }} className={classes.paper}>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <CardMedia
              className={classes.logo}
              image={logo}
            // title="Contemplative Reptile"
            />
          </div>
          {/* <div style={{ width: 360 }}>

          {alert && (
            <Alert severity={alert.alertType}>{alert.message}</Alert>
          )}
        </div> */}
          {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
          {/* <div style={{ justifyContent: "center" }}> */}
          <Typography variant="h5">
            Impru Support
          </Typography>
          {/* <Typography variant="h6">Login</Typography> */}
          {/* </div> */}

          <form className={classes.form} noValidate>
            <CustomTextField
              error={emailError}
              label="Email"
              value={email}
              onChange={onChangeEmail}
            />
            <PasswordTextField
              error={passwordError}
              label="Password"
              type="password"
              value={password}
              onChange={onChangePassword}
            />

            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              style={{ width: 360, height: 50 }}
              className={classes.submit}
              onClick={login}
            >
              Login
            </Button>
            <div style={{ width: 360 }}>

              {alert && (
                <Alert icon={false} severity={alert.alertType}>{alert.message}</Alert>
              )}
              {error && (
                <Alert icon={false} severity="error" >{error}</Alert>
              )}
              {!error && (
                <div style={{ height: 48, width: 360, background: 'white' }} ></div>
              )}
            </div>
            <div>
              <div style={{ textAlign: "center" }}>
                <Link href="/forgot-password" variant="body2">
                  Forgot password?
                </Link>
              </div>
              {/* <div style={{ textAlign: "center" }}>
              <Link href="/verify-account" variant="body2">
                {"New user? Click here to register"}
              </Link>
            </div> */}
            </div>
          </form>
        </Card>

        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
};

export default SignIn;
