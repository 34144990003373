import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import { MenuItem, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SimpleSelect = (props) => {
  const classes = useStyles();

  return (
    <div>
      <FormControl
        variant="outlined"
        style={{ minWidth: props.minWidth }}
        className={classes.formControl}
      >
        <InputLabel id="labelid">{props.label}</InputLabel>
        <Select
          labelId="labelId"
          id="selectId"
          value={props.value}
          onChange={props.onChange}
          label={props.label}
        >
          {/* <MenuItem value="">
            <em>{props.firstMenu}</em>
          </MenuItem> */}
          {props.options.map((option, index) => (
            <MenuItem key={index.toString()} value={index}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
        }}
        // className={classes.formControl}
        variant="body2"
        color="secondary"
      >
        {props.error && props.error}
      </Typography>
    </div>
  );
};

export default SimpleSelect;
