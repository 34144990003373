import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { baseUrl } from "../config/baseUrl";
import { useSelector } from "react-redux";

import scienceImage from "../assets/images/standard.png";
import {
  CardMedia,
  Card,
  Typography,
  Button,
  CardActions,
  CardContent,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    minWidth: 300,
    margin: 8,
  },
  media: {
    height: 100,
    width: 100,
    //borderRadius: 60,
  },
}));

const StandardItem = (props) => {
  const classes = useStyles();
  const { boardId, standardId } = props;
  const instituteId = useSelector((state) => state.institutes.instituteId);
  const [instituteStandards, setInstituteBoards] = useState([]);

  // console.log(instituteStandards);
  const getInstituteStandardsByBoardId = async () => {
    const token = localStorage.getItem("token");
    console.log("calling");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const res = await axios.get(
          `${baseUrl}/api/institute-content/standard/${instituteId}/${boardId}`,
          config
        );
        console.log(res);
        if (res.status == 200) {
          setInstituteBoards(res.data.instituteStandards);
        }
      } catch (err) {}
    }
  };

  const add = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        instituteId,
        boardId,
        standardId,
      });
      try {
        const res = await axios.post(
          `${baseUrl}/api/institute-content/standard/add`,
          body,
          config
        );

        if (res.status == 200) {
          getInstituteStandardsByBoardId();
        }
      } catch (err) {}
    }
  };

  const remove = async (id) => {
    const token = localStorage.getItem("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const res = await axios.delete(
          `${baseUrl}/api/institute-content/standard/${id}`,
          config
        );

        if (res.status == 200) {
          getInstituteStandardsByBoardId();
        }
      } catch (err) {}
    }
  };
  useEffect(() => {
    getInstituteStandardsByBoardId();
  }, []);

  return (
    <Card className={classes.root} key={props.boardId.toString()}>
      <div style={{ justifyContent: "center", display: "flex", marginTop: 8 }}>
        <CardMedia
          className={classes.media}
          image={props.imageUrl ? props.imageUrl : scienceImage}
          title="Contemplative Reptile"
        />
      </div>
      <CardContent>
        <Typography
          style={{
            textAlign: "center",
          }}
          gutterBottom
          variant="subtitle1"
          component="h4"
        >
          {props.name}
        </Typography>
      </CardContent>

      <CardActions
        style={{
          justifyContent: "center",
          backgroundColor: "whitesmoke",
        }}
      >
        {instituteStandards.some((b) => b.standardId == props.standardId) ? (
          <Button
            size="small"
            color="secondary"
            onClick={() => {
              remove(
                instituteStandards.find((b) => b.standardId == props.standardId)
                  ._id
              );
            }}
          >
            Remove
          </Button>
        ) : (
          <Button size="small" color="primary" onClick={add}>
            Add
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default StandardItem;
