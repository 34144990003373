import React, { useEffect, useState } from "react";
import Drawer from "../components/Drawer";
import Select from "../components/Select";
import CustomSelect from "../components/CustomSelect";

import CustomTextField from "../components/CustomTextField";
import SubjectItem from "../components/SubjectItem";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import Breadcrumbs from "../components/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";

import {
  makeStyles,
  Grid,
  Button,
  Divider,
  Typography,
  TextField,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
  getInstituteSyllabus,
  getAllInstitutesByMainInstiuteId,
} from "../store/actions/Institutes";
import { getAllMainInsitutes } from "../store/actions/MainInstitute";
import { createGroup } from "../store/actions/Groups";
import {
  getAllContentProvidersByInstituteId,
  getBoardsByContentProviderId,
  getStandardsByBoardId,
  getCoursesByStandardId,
  getSubjectsByCourseId,
} from "../store/actions/InstituteContent";
import AppBar from '../components/AppBar'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
  },
  multiText: {
    "& > *": {
      margin: theme.spacing(1),
      width: "400px",
    },
  },
}));

const NewGroup = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mainInsts = useSelector((state) => state.mainInstitute.mainInstitutes);
  const instituteContentProviders = useSelector(
    (state) => state.instituteContent.instituteContentProviders
  );
  const instituteBoards = useSelector(
    (state) => state.instituteContent.instituteBoards
  );
  const instituteStandards = useSelector(
    (state) => state.instituteContent.instituteStandards
  );
  const instituteCourses = useSelector(
    (state) => state.instituteContent.instituteCourses
  );
  const instituteSubjects = useSelector(
    (state) => state.instituteContent.instituteSubjects
  );

  const insts = useSelector((state) => state.institutes.institutes);
  const groupError = useSelector((state) => state.groups.error);
  const groupMessage = useSelector((state) => state.groups.message);
  const alert = useSelector((state) => state.alert.alert);
  const [mainInstitute, setMainInstitute] = useState("");
  const [institute, setInstitute] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [contentProvider, setContentProvider] = useState("");
  const [board, setBoard] = useState("");
  const [standard, setStandard] = useState("");
  const [course, setCourse] = useState("");
  const [subjects, setSubjects] = useState([]);

  const [mainInstError, setMainInstError] = useState("");
  const [instError, setInstError] = useState("");
  const [contentProviderError, setContentProviderError] = useState("");
  const [boardError, setBoardError] = useState("");
  const [standardError, setStandardError] = useState("");
  const [courseError, setCourseError] = useState("");
  const [nameError, setnameError] = useState("");
  const [subjectsError, setSubjectsError] = useState("");
  useEffect(() => {
    dispatch(getAllMainInsitutes());
    setSubjects(instituteSubjects);
  }, [dispatch, instituteSubjects]);

  const handleMainInstituteChange = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      setMainInstitute([e.target.value]);
      dispatch(getAllInstitutesByMainInstiuteId(mainInsts[e.target.value]._id));
      setMainInstError("");
      setInstError("");
      setBoard("");
      setStandard("");
      setCourse("");
      setSubjects([]);
    }
  };
  const handleInstituteChange = (e) => {
    e.preventDefault();

    if (e.target.value !== "") {
      setInstitute([e.target.value]);
      dispatch(
        getAllContentProvidersByInstituteId({
          instituteId: insts[e.target.value]._id,
        })
      );
      setInstError("");
      setBoard("");
      setStandard("");
      setCourse("");
      setSubjects([]);
    }
  };

  const handleGroupNameChange = (e) => {
    // e.preventDefault();
    // console.log("grp name");
    //console.log(e.target.value);
    setName(e.target.value);
    setnameError("");
  };
  const handleDescriptionChange = (e) => {
    e.preventDefault();
    //console.log("change");
    //console.log(e.target.value);
    setDescription(e.target.value);
  };
  const handleContentProviderChange = (e) => {
    e.preventDefault();
    // console.log("value" + e.target.value);
    if (e.target.value !== "") {
      setContentProvider([e.target.value]);
      dispatch(
        getBoardsByContentProviderId({
          instituteId: insts[institute]._id,
          contentProviderId:
            instituteContentProviders[e.target.value].contentProviderId,
        })
      );
      setContentProviderError("");
      setBoardError("");
      setStandard("");
      setCourse("");
      setSubjects([]);
    }
  };
  const handleBoardChange = (e) => {
    e.preventDefault();

    if (e.target.value !== "") {
      setBoard([e.target.value]);
      dispatch(
        getStandardsByBoardId({
          instituteId: insts[institute]._id,
          boardId: instituteBoards[e.target.value].boardId,
        })
      );
      setBoardError("");
      setStandard("");
      setCourse("");
      setSubjects([]);
    }
  };
  // console.log(standard);
  const handleStandardChange = (e) => {
    e.preventDefault();

    if (e.target.value !== "") {
      setStandard([e.target.value]);
      dispatch(
        getCoursesByStandardId({
          instituteId: insts[institute]._id,
          standardId: instituteStandards[e.target.value].standardId,
        })
      );
      setStandardError("");
      setCourse("");
      setSubjects([]);
    }
  };
  const handleCourseChange = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      setCourse([e.target.value]);
      dispatch(
        getSubjectsByCourseId({
          instituteId: insts[institute]._id,
          courseId: instituteCourses[e.target.value].courseId,
        })
      );
      setCourseError("");
    }
  };

  const onSelectSubjects = (sub) => {
    // console.log(sub);
    const subs = subjects.map((s) => {
      if (sub._id === s._id) {
        // console.log(s);
        s.isSelected = !s.isSelected;
      }
      return s;
    });
    setSubjects(subs);
    setSubjectsError("");
  };
  const validate = () => {
    if (mainInstitute === "") {
      setMainInstError("Select MainInstitute");
      //console.log(" coming");
      return false;
    }

    if (institute === "") {
      setInstError("Institute  is Required");
      // console.log(" coming");
      return false;
    }
    if (board === "") {
      setBoardError("Board  is Required");
      return false;
    }

    if (standard === "") {
      setStandardError("Standard is Required");
      return false;
    }

    if (course === "") {
      setCourseError("Course is Required");
      return false;
    }

    if (name === "") {
      setnameError("Group Name is Required");
      return false;
    }
    if (name.length < 8) {
      setnameError("Group Name is too short");
      return false;
    }
    let subIds = [];
    if (subjects) {
      subjects.map((sub) => {
        if (sub.isSelected === true) {
          subIds.push(sub._id);
        }
      });
    }
    if (subIds.length <= 0) {
      setSubjectsError("Select Atleast one subject");
      return false;
    }

    return true;
  };
  const goBack = () => {
    props.history.goBack();
  };
  const createNewGroup = () => {
    let subIds = [];

    //
    const isValid = validate();

    if (isValid) {
      let instituteId = insts[institute]._id; // this inside isValied block, otherwise give error

      if (subjects) {
        subjects.map((sub) => {
          if (sub.isSelected === true) {
            subIds.push(sub.subjectId);
          }
        });
      }

      const grp = {
        instituteId,
        name,
        description,
        boardId: instituteBoards[board].boardId,
        standardId: instituteStandards[standard].standardId,
        courseId: instituteCourses[course].courseId,
        subjectIds: subIds,
      };

      dispatch(createGroup(grp));
      setInstitute("");
      setName("");
      setDescription("");
      setBoard("");
      setStandard("");
      setCourse("");
      setSubjects([]);
    }
  };

  return (
    <div>
      <Drawer>
        <Breadcrumbs />
        <div
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            // alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            //marginLeft: 40,
          }}
        >
           <div style={{ width: 400 }}>
           
           {alert && (
         <Alert severity={alert.alertType}>{alert.message}</Alert>
       )}
       </div>
          <CustomTextField
            label="Group Name"
            value={name}
            onChange={handleGroupNameChange}
            error={nameError}
          />

          <form className={classes.multiText} noValidate autoComplete="off">
            <TextField
              label="Description"
              variant="outlined"
              color="primary"
              multiline
              rows={4}
              value={description}
              onChange={handleDescriptionChange}
              style={{ width: 360 }}
            />
          </form>
          <Select
            options={mainInsts}
            firstMenu="Select Main Institute"
            label="Main Institute"
            value={mainInstitute}
            onChange={handleMainInstituteChange}
            error={mainInstError}
          />
          <Select
            options={insts}
            firstMenu="Select Institute"
            label="Institute"
            value={institute}
            onChange={handleInstituteChange}
            error={instError}
          />
          <Select
            options={instituteContentProviders}
            firstMenu="Select Content Provider"
            label="Content Provider"
            value={contentProvider}
            onChange={handleContentProviderChange}
            error={contentProviderError}
          />
          <Select
            options={instituteBoards}
            firstMenu="Select Board"
            label="Board"
            value={board}
            onChange={handleBoardChange}
            error={boardError}
          />
          <Select
            options={instituteStandards}
            firstMenu="Select Standard"
            label="Standard"
            value={standard}
            onChange={handleStandardChange}
            error={standardError}
          />
          <Select
            options={instituteCourses}
            firstMenu="Select Course"
            label="Course"
            value={course}
            onChange={handleCourseChange}
            error={courseError}
          />

          <Divider />
          <div>
            <Typography variant="h6" component="h5">
              Subjects
            </Typography>
            <Typography color="textSecondary" variant="subtitle1" gutterBottom>
              Please Select Subjects, so that the group will have access to
              those choosen subjects
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              // flex: 1,
              alignItems: "flex-start",
              justifyContent: "space-around",
            }}
          >
            <Grid
              //style={{ display: "flex", flex: 1 }}
              container
              // justify="center"
              //alignItems="center"
              spacing={2}
            >
              {subjects.length > 0 ? (
                subjects.map((sub, i) => (
                  <Grid
                    key={i.toString()}
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                      justifyContent: "center",
                      //alignContent: "center",
                      textAlign: "center",
                    }}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    xl={6}
                    // spacing={2}
                  >
                    <SubjectItem
                      onChange={() => onSelectSubjects(sub)}
                      subject={sub}
                    />
                  </Grid>
                ))
              ) : (
                <div
                  style={{
                    // flex: 1,
                    display: "flex",

                    width: 500,
                    height: 100,
                    backgroundColor: "whitesmoke",
                    marginTop: 16,
                    borderRadius: 8,
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    No Subjects are there to show, Please Choose all the fields
                    from the above dropdown
                  </Typography>
                </div>
              )}
            </Grid>
          </div>
          <Typography
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
            // className={classes.formControl}
            variant="body2"
            color="secondary"
          >
            {subjectsError}
          </Typography>

          <div style={{ marginTop: 16 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className={classes.button}
              startIcon={<CancelIcon />}
              onClick={goBack}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={createNewGroup}
              className={classes.button}
              startIcon={<SaveIcon />}
            >
              Create
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default NewGroup;
