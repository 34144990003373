import React, { useEffect } from "react";
import Drawer from "../components/Drawer";
import moment from "moment";
import { makeStyles, Tooltip, Fab, Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import Theme from "../Theme";
import Select from "../components/Select";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Breadcrumbs from "../components/Breadcrumbs";
import { getAllInstitutesByMainInstiuteId } from "../store/actions/Institutes";
import { getInstituteTeachers } from "../store/actions/Teachers";
import { useSelector, useDispatch } from "react-redux";
import { getAllMainInsitutes } from "../store/actions/MainInstitute";
import AppBar from '../components/AppBar'

const useStyles = makeStyles((theme) => ({
  table: {
    "& tbody>.MuiTableRow-root:hover": {
      background: "whitesmoke",
    },
  },
}));

const Teachers = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [institute, setInstitute] = React.useState("");
  const [mainInstitute, setMainInstitute] = React.useState("");
  const mainInsts = useSelector((state) => state.mainInstitute.mainInstitutes);
  const insts = useSelector((state) => state.institutes.institutes);
  const tchrs = useSelector((state) => state.teachers.teachers);

  const handleMainInstituteChange = (e) => {
    e.preventDefault();
    setMainInstitute([e.target.value]);
    dispatch(getAllInstitutesByMainInstiuteId(mainInsts[e.target.value]._id));
  };
  const handleInstituteChange = (e) => {
    e.preventDefault();
    setInstitute([e.target.value]);
    dispatch(getInstituteTeachers(insts[e.target.value]._id));
  };
  useEffect(() => {
    dispatch(getAllMainInsitutes());
  }, [dispatch]);

  const col = [
    {
      title: "Image",
      field: "imageUrl",
      render: (rowData) => (
        <img
          src={rowData.imageUrl}
          style={{ width: 40, height: 40, borderRadius: "50%" }}
        />
      ),
      export: false,
    },

    {
      field: "name",
      title: "Name",
      cellStyle: {
        width: 150,
        maxWidth: 200,
      },
      headerStyle: {
        width: 150,
        maxWidth: 200,
      },
    },
    {
      field: "email",
      title: "Email",
      cellStyle: {
        width: 150,
        maxWidth: 200,
      },
      headerStyle: {
        width: 150,
        maxWidth: 200,
      },
    },
    // { field: "phoneNumber", title: "Phone #", width: 150 },
    // { field: "isVerified", title: "Account Verified?", width: 130 },
    // { field: "role", title: "Role", width: 100 },
    {
      field: "createdAt",
      title: "Created on",
      width: 130,
      render: (rowData) => <div>{moment(rowData.createdAt).format('DD/MM/YYYY')}</div>,
    },
  ];
  return (
    <div>
         <AppBar />
            <div style={{ padding: 16 }}>
        <Breadcrumbs />
        <div
          style={{
            //width: 800,
            alignItems: "center",
            //justifyContent: "center",
            //alignSelf: "center",
            //alignContent: "center",
            //flex: 1,
            // display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ width: 800 }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={0}
            >
              <Grid item sm={12} xs={12} md={6} lg={6}>
                <Select
                  options={mainInsts}
                  firstMenu="Select Main Institute"
                  label=" Main Institute"
                  value={mainInstitute}
                  onChange={handleMainInstituteChange}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={6} lg={6}>
                <Select
                  options={insts}
                  firstMenu="Select Institute"
                  label="Institute"
                  value={institute}
                  onChange={handleInstituteChange}
                />
              </Grid>
            </Grid>
          </div>

          <div
            style={{
              width: 800,
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center",
              //flex: 1,
              //display: "flex",
            }}
          >
            <div className={classes.table} style={{ maxWidth: "100%" }}>
              <MaterialTable
                theme={Theme}
                columns={col}
                data={tchrs}
                title="Teachers"
                options={{
                  exportButton: true,
                  // selection: true,
                }}
              />
            </div>
            {/* <Table rows={tchrs} /> */}
          </div>
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            bottom: 0,
            right: 0,
            marginBottom: 16,
            marginRight: 16,
            position: "fixed",
          }}
        >
          <Tooltip title="Create Teacher" aria-label="add">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => {
                props.history.push("/create-teacher");
              }}
            >
              <PersonAddIcon style={{ color: "white" }} />
            </Fab>
          </Tooltip>
          <Tooltip title="Create Multiple Teachers" aria-label="add">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => {
                props.history.push("/create-multiple-teachers");
              }}
            >
              <GroupAddIcon style={{ color: "white" }} />
            </Fab>
          </Tooltip>
        </div> */}
      </div>
    </div>
  );
};

export default Teachers;
