import React from "react";
import Drawer from "../components/Drawer";
import Typography from "@material-ui/core/Typography";
import { makeStyles, } from "@material-ui/core";
import Breadcrumbs from "../components/Breadcrumbs";
import {
    Button,
    Card,
    Divider,
    List,
    ListItem,
    ListItemText,
    Modal
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { getStudentByEmail, resetStudentPassword, } from "../store/actions/Students";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import UserListItem from "../components/InstituteDetailListItem";
import InstituteDetailListItem from "../components/InstituteDetailListItem";
import DateListItem from "../components/DateListItem";

import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import image from "../assets/images/round-user-icon.png";
import CustomTextField from '../components/CustomTextField';
import AppBar from '../components/AppBar'


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 800,
    },
    media: {
        height: 80,
        width: 80,
        margin: 8,
        borderRadius: 70,
        borderWidth: 3, borderColor: "red"
    },
    paper: {
        position: "absolute",
        width: 600,
        // height: 300,
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
}));
function rand() {
    return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}


const StudentSupport = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const alert = useSelector((state) => state.alert.alert);
    const s = useSelector((state) => state.auth.user);
    const teacher = useSelector((state) => state.students.student);
    const [email, setEmail] = React.useState("");
    const [emailError, setEmailError] = React.useState("");
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const handleEmailChange = (e) => {
        e.preventDefault();
        //console.log("change");
        //console.log(e.target.value);
        setEmail(e.target.value);
        setEmailError("");

    };
    const onClickGetTeacher = () => {
        dispatch(getStudentByEmail({ email }))
    }
    const onCLickResetPassword = () => {
        dispatch(resetStudentPassword({ email }))
        setOpen(false)
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h3 style={{ margin: 8 }}>Reset Password</h3>
            <p id="simple-modal-description" style={{ margin: 8 }}>
                Are you sure want to reset the password for the user?
            </p>

            <div style={{ display: 'flex', justifyContent: "space-evenly" }}>
                <Button
                    variant="contained"
                    style={{ margin: 8, minWidth: 170, height: 50 }}
                    color="primary"

                    onClick={onCLickResetPassword}
                >
                    Reset
                </Button>
                <Button
                    variant="contained"
                    style={{ margin: 8, minWidth: 170, height: 50 }}
                    color="secondary"
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Cancel
                </Button>

            </div>
        </div>
    );
    return (
        <div>

            <AppBar />
            <div style={{ padding: 16 }}>
                <Breadcrumbs />
                <div
                    style={{
                        alignItems: "center",
                    }}
                >
                    <div style={{ flexDirection: "row", display: 'flex', justifyContent: 'flex-start', alignItems: "center", }}>
                        <CustomTextField
                            label="Email"
                            value={email}
                            onChange={handleEmailChange}
                            error={emailError}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.button}
                            style={{ height: 50 }}
                            onClick={onClickGetTeacher}
                        >
                            Search
                        </Button>
                    </div>
                    {alert && (
                        <Alert severity={alert.alertType}>{alert.message}</Alert>
                    )}
                    {
                        teacher && <Card className={classes.root}>
                            <CardContent>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <div style={{ justifyContent: "center", display: "flex", flexDirection: "column" }}>

                                        <Typography
                                            style={{
                                            }}
                                        >
                                            {teacher.name}
                                        </Typography>
                                        <Typography
                                            style={{
                                            }}
                                        >
                                            {teacher.email}
                                        </Typography>
                                    </div>
                                    <div style={{ justifyContent: "center", display: "flex" }}>
                                        <CardMedia
                                            className={classes.media}
                                            image={teacher.imageUrl ? teacher.imageUrl : image}
                                        />
                                    </div>

                                </div>
                                {/* <Typography
                                    style={{
                                        textAlign: "center",
                                    }}
                                    gutterBottom
                                    variant="h5"
                                    component="h2"
                                >
                                    {teacher.name}
                                </Typography> */}
                                <Divider />
                                <List>
                                    <ListItem selected={true}>
                                        <ListItemText primary="Parent Details" />
                                    </ListItem>
                                </List>

                                {/* <UserListItem
                                    answer={
                                        teacher.name
                                    }
                                    title="Name"
                                />

                                <UserListItem
                                    answer={teacher.email}
                                    title="Email"
                                /> */}
                                {/* <UserListItem
                                    answer={teacher.phoneNumber}
                                    title="Phone Number "
                                /> */}
                                <UserListItem
                                    answer={
                                        teacher.parentName
                                    }
                                    title="Parent Name"
                                />

                                <UserListItem
                                    answer={teacher.parentEmail}
                                    title="Parent Email"
                                />
                                {/* <UserListItem
                                    answer={teacher.parentPhoneNumber}
                                    title="Parent Phone Number "
                                /> */}
                                <Divider />
                                <List>
                                    <ListItem selected={true}>
                                        <ListItemText primary="Activities" />
                                    </ListItem>
                                </List>
                                <DateListItem
                                    answer={teacher.lastLoggedInAt}
                                    title="Last logged-in at "
                                />
                                <DateListItem
                                    answer={teacher.lastPasswordChangedAt}
                                    title="Last password changed at "
                                />
                                <DateListItem
                                    answer={teacher.lastPasswordResetAt}
                                    title="last Password reset at "
                                />
                                <Divider />
                                <List>
                                    <ListItem selected={true}>
                                        <ListItemText primary="Institute Details" />
                                    </ListItem>
                                </List>
                                <InstituteDetailListItem
                                    answer={
                                        teacher.institute.name
                                    }
                                    title="Institute Name"
                                />
                                <InstituteDetailListItem
                                    answer={
                                        teacher.group.standard.name
                                    }
                                    title="Class"
                                />
                                <InstituteDetailListItem
                                    answer={
                                        teacher.group.name
                                    }
                                    title="Group Name"
                                />
                                {/* <InstituteDetailListItem
                                    answer={
                                        teacher.institute.instituteType
                                    }
                                    title="Institute Type"
                                />
                                <InstituteDetailListItem
                                    answer={
                                        teacher.institute.instituteMode
                                    }
                                    title="Institute Category"
                                /> */}
                                <InstituteDetailListItem
                                    answer={teacher.institute.email}
                                    title="Email"
                                />
                                <InstituteDetailListItem
                                    answer={teacher.institute.phoneNumber}
                                    title="Phone Number "
                                />
                                <Divider />
                                {/* <List>
                                    <ListItem selected={true}>
                                        <ListItemText primary="Actions" />
                                    </ListItem>
                                </List> */}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    style={{ height: 50, marginTop: 8 }}

                                    className={classes.button}
                                    onClick={() => { setOpen(true) }}

                                >
                                    Reset Password
                                </Button>
                            </CardContent>
                        </Card>
                    }

                    <Modal
                        open={open}
                        onClose={() => { setOpen(false) }}
                    >
                        {body}
                    </Modal>
                </div>
            </div>

        </div>
    );
};

export default StudentSupport;
