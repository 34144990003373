import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
//import { BrowserHistory } from "history";

import "./App.css";
import PrivateRoute from "./routing/PrivateRoute";
import MainInstituteAdmins from "./pages/MainInstituteAdmins";
import Institutes from "./pages/Institutes";
import MainInstitutes from "./pages/MainInstitutes";
import CreateInstitute from "./pages/CreateInstitute";
import CreateMainInstitute from "./pages/CreateMainInstitute";

import CreateMIAdmin from "./pages/CreateMIAdmin";
import Groups from "./pages/Groups";
import NewGroup from "./pages/NewGroup";
import Teachers from "./pages/Teachers";
import Students from "./pages/Students";
import Account from "./pages/Account";
import SignIn from "./pages/SignIn";
import ForgotPassword from "./pages/ForgotPassword";
import VerifyAccount from "./pages/VerifyAccount";
import EditGroup from "./pages/EditGroup";
import CreateStudent from "./pages/CreateStudent";
import CreateMultipleStudents from "./pages/CreateMultipleStudents";
import CreateMultipleTeachers from "./pages/CreateMultipleTeachers";

import StudentSupport from './pages/StudentSupport';
import TeacherSupport from "./pages/TeacherSupport";
import ParentSupport from './pages/ParentSupport';


import CreateTeacher from "./pages/CreateTeacher";
import AddTeacher from "./pages/AddTeacher";
import InstituteGroups from "./pages/InstituteGroups";
import GroupDetail from "./pages/GroupDetails";
import InstituteDetail from "./pages/InstituteDetail";
import PageNotFound from "./pages/PageNotFound";
import { Provider } from "react-redux";
import store from "./redux-store/store";
import ImpruAdmins from "./pages/ImpruAdmins";
import CreateImpruAdmin from "./pages/CreateImpruAdmin";
import InstituteContent from "./pages/InstituteContent";
const Routes = (props) => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <PrivateRoute exact path="/institutes" component={Institutes} />
          {/* <PrivateRoute exact path="/impru-admins" component={ImpruAdmins} /> */}
          {/* <PrivateRoute
            exact
            path="/create-impru-admin"
            component={CreateImpruAdmin}
          /> */}
          {/* <PrivateRoute
            exact
            path="/institute-admins"
            component={MainInstituteAdmins}
          /> */}

          {/* <PrivateRoute
            exact
            path="/create-mi-admin"
            component={CreateMIAdmin}
          /> */}
          {/* <PrivateRoute
            exact
            path="/create-main-institute"
            component={CreateMainInstitute}
          /> */}
          {/* <PrivateRoute
            exact
            path="/create-institute"
            component={CreateInstitute}
          /> */}
          {/* <PrivateRoute
            exact
            path="/institute-content/:instiuteId"
            component={InstituteContent}
          /> */}

          {/* <PrivateRoute
            exact
            path="/institute-details/:id"
            component={InstituteDetail}
          /> */}
          <PrivateRoute
            exact
            path="/institute-groups"
            component={InstituteGroups}
          />
          <PrivateRoute exact path="/groups" component={Groups} />
          {/* <PrivateRoute exact path="/create-group" component={NewGroup} /> */}
          {/* <PrivateRoute exact path="/edit-group" component={EditGroup} /> */}
          <PrivateRoute
            exact
            path="/institute-groups-detail/:groupName"
            component={GroupDetail}
          />
          {/* <PrivateRoute exact path="/add-teacher" component={AddTeacher} /> */}
          <PrivateRoute exact path="/teachers" component={Teachers} />
          {/* <PrivateRoute
            exact
            path="/create-teacher"
            component={CreateTeacher}
          /> */}
          {/* <PrivateRoute
            exact
            path="/create-multiple-teachers"
            component={CreateMultipleTeachers}
          /> */}
          <PrivateRoute exact path="/students" component={Students} />
          {/* <PrivateRoute
            exact
            path="/create-student"
            component={CreateStudent}
          /> */}
          {/* <PrivateRoute
            exact
            path="/create-multiple-students"
            component={CreateMultipleStudents}
          /> */}
          <PrivateRoute exact path="/My Account" component={Account} />
          <Route exact path="/sign-in" component={SignIn} />
          <Route exact path="/Forgot Password" component={ForgotPassword} />
          <Route exact path="/Verify Account" component={VerifyAccount} />
          <PrivateRoute exact path="/" component={MainInstitutes} />
          <PrivateRoute exact path="/Teacher Support" component={TeacherSupport} />
          <PrivateRoute exact path="/Student Support" component={StudentSupport} />
          <PrivateRoute exact path="/Parent Support" component={ParentSupport} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
};

export default Routes;
