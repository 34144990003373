import React, { useEffect } from "react";
import Drawer from "../components/Drawer";
import moment from "moment";
import {
  getAllInstitutes,
  getInstituteDetail,
} from "../store/actions/Institutes";
import MaterialTable from "material-table";
import { getAllMainInsitutes } from "../store/actions/MainInstitute";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Grid, Tooltip, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AppBar from '../components/AppBar'

import InstituteItem from "../components/InstituteItem";
import Breadcrumbs from "../components/Breadcrumbs";
import Theme from "../Theme";
const useStyles = makeStyles((theme) => ({
  appMain: {
    // paddingLeft: "300px",
    paddingTop: "100px",
    width: "100%",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  table: {
    "& tbody>.MuiTableRow-root:hover": {
      background: "whitesmoke",
    },
  },
}));

const MainInstitutes = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const maininsts = useSelector((state) => state.mainInstitute.mainInstitutes);
  //const user = useSelector((state) => state.auth);
  //console.log(insts);
  const onClickGetInstituteDetails = (instituteId) => {
    //console.log(instituteId);
    // dispatch(getInstituteDetail(instituteId));
    //props.history.push("/institute-details");
  };
  const onClickGetInstituteGroups = (instituteId) => {
    //dispatch(getInstituteGroups(instituteId));
    // props.history.push("/institute-groups");
  };
  useEffect(() => {
    dispatch(getAllMainInsitutes());
  }, [dispatch]);
  const col = [
    { field: "name", title: "Name", width: 150 },
    { field: "email", title: "Email", width: 150 },
    { field: "phoneNumber", title: "Phone #", width: 150 },
    // { field: "isActive", title: "Active", width: 150 },
    {
      field: "createdAt",
      title: "Created on",
      width: 130,
      render: (rowData) => <div>{moment(rowData.createdAt).format('DD/MM/YYYY')}</div>,
    },
  ];

  return (
    <div>
      <Drawer>
        <Breadcrumbs />
        <div
          style={{
            //width: 800,
            alignItems: "center",
            //justifyContent: "center",
            //alignSelf: "center",
            //alignContent: "center",
            //flex: 1,
            // display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: 800,
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center",
              //flex: 1,
              //display: "flex",
            }}
          >
            <div className={classes.table} style={{ maxWidth: "100%" }}>
              <MaterialTable
                theme={Theme}
                columns={col}
                data={maininsts}
                title="Main Institutes"
                options={{
                  exportButton: true,
                  //selection: true,
                }}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            bottom: 0,
            right: 0,
            marginBottom: 16,
            marginRight: 16,
            position: "fixed",
          }}
        >
          <Tooltip title="New Institute" aria-label="add">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => {
                props.history.push("/create-main-institute");
              }}
            >
              <AddIcon style={{ color: "white" }} />
            </Fab>
          </Tooltip>
        </div>
      </Drawer>
    </div>
  );
};

export default MainInstitutes;
