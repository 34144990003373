import React from "react";
import CustomTextField from "./CustomTextField";
import {
  makeStyles,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core";
import Select from "./Select";
const useStyles = makeStyles((theme) => ({
  multiText: {
    "& > *": {
      margin: theme.spacing(1),
      width: "400px",
    },
  },
}));

const InstituteDetailForm = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Select
        options={props.mainInsts}
        firstMenu="Select Main Institute"
        label="Main Institute"
        value={props.mainInstitute}
        onChange={props.handleMainInstituteChange}
        error={props.instError}
      />

      
      <CustomTextField
        label="Institute Name"
        value={props.name}
        onChange={props.handleNameChange}
        error={props.nameError}
      />

      <form className={classes.multiText} noValidate autoComplete="off">
        <TextField
          label="Description"
          variant="outlined"
          color="primary"
          multiline
          rows={4}
          value={props.description}
          onChange={props.handleDescriptionChange}
          style={{ width: 360 }}
        />
      </form>
      <Select
        options={props.instituteModes}
        firstMenu="Select Institute Mode"
        label="Institute Mode"
        value={props.instituteMode}
        onChange={props.handleInstituteModeChange}
        error={props.instituteModeError}
      />
      <Select
        options={props.instituteTypes}
        firstMenu="Select Institute Category"
        label="Institute Category"
        value={props.instituteType}
        onChange={props.handleInstituteTypeChange}
        error={props.instituteTypeError}
      />
      <FormControl>
        <FormLabel>Payment By</FormLabel>
        <RadioGroup
          name="PAYMENT"
          value={props.pay}
          onChange={props.handlePayChange}
        >
          <FormControlLabel
            value="INSTITUTE"
            control={<Radio />}
            label="Institute"
          />
          <FormControlLabel
            value="STUDENT"
            control={<Radio />}
            label="Student"
          />
        </RadioGroup>
      </FormControl>
      
      <div
        style={{
          flexDirection: "row",
          width: 360,
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          onClick={props.goBack}
          style={{ margin: 16, width: 100 }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={props.gotoNext}
          style={{ margin: 16, width: 100 }}
        >
          Continue
        </Button>
      </div>
    </React.Fragment>
  );
};

export default InstituteDetailForm;
