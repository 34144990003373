import React from "react";
import Drawer from "../components/Drawer";
import CustomTextField from "../components/CustomTextField";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { Alert } from "@material-ui/lab";
import { makeStyles, Button } from "@material-ui/core";
import Breadcrumbs from "../components/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { createImpruAdmin } from "../store/actions/ImpruAdmins";
import AppBar from '../components/AppBar'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const CreateImpruAdmin = (props) => {
  const classes = useStyles();
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const dispatch = useDispatch();
  const message = useSelector((state) => state.impruAdmins.message);
  const error = useSelector((state) => state.impruAdmins.error);
  const alert = useSelector((state) => state.alert.alert);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhone] = React.useState("");

  const [nameError, setNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");

  const handleNameChange = (e) => {
    e.preventDefault();

    setName(e.target.value);
    setNameError("");
  };
  const handleEmailChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
    setEmailError("");
  };

  const handlePhoneNumberChange = (e) => {
    e.preventDefault();
    setPhone(e.target.value);
    setPhoneError("");
  };

  const validate = () => {
    if (name === "") {
      setNameError("Name is required");
      return false;
    }
    if (name.length < 8) {
      setNameError("Name is too small");
      return false;
    }
    if (email === "") {
      setEmailError("Email is required");
      return false;
    }
    if (email.length > 0) {
      if (!regexp.test(email)) {
        setEmailError("Invalid Email");
        return false;
      }
    }
    if (phoneNumber === "") {
      setPhoneError("Phone is required");
      return false;
    }
    if (phoneNumber.length > 0) {
      if (!phoneno.test(phoneNumber)) {
        setPhoneError("Invalid Phone Number");
        return false;
      }
    }

    return true;
  };
  const goBack = () => {
    props.history.goBack();
  };

  const onClickCreateImpruAdmin = () => {
    const isValid = validate();
    if (isValid) {
      dispatch(
        createImpruAdmin({
          name,
          email,
          phoneNumber,
          isAdmin: true,
        })
      );
    //   setName("");
    //   setEmail("");
    //   setPhone("");
    }
  };
  return (
    <div>
      <Drawer>
        <Breadcrumbs />
        <div
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            // alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            // marginLeft: 40,
          }}
        >
          <div style={{ width: 400 }}>
           
              {alert && (
            <Alert severity={alert.alertType}>{alert.message}</Alert>
          )}
          </div>

          <CustomTextField
            label="Full Name"
            value={name}
            onChange={handleNameChange}
            error={nameError}
          />
          <CustomTextField
            label="Email"
            value={email}
            onChange={handleEmailChange}
            error={emailError}
          />
          <CustomTextField
            label="Phone Number"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            error={phoneError}
          />

          <div style={{ marginTop: 16 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className={classes.button}
              onClick={goBack}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              onClick={onClickCreateImpruAdmin}
            >
              Create Impru Admin
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default CreateImpruAdmin;
