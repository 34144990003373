import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Toolbar from '@material-ui/core/Toolbar';
import { Typography, Avatar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import { ExitToApp, AccountBalance, Person } from '@material-ui/icons';
import logo from "../assets/images/impru-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/actions/Auth";
import { createHashHistory } from 'history'
import { withRouter } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const CustomAppBar = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const classes = useStyles();
  const history = createHashHistory()
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToInstitutes = () => {
    props.history.push(
      "/Institutes"
    )
  }
  const gotoProfile = () => {
    props.history.push(
      "/My Account"
    )
  }
  const goToLogin = () => {
    props.history.push(
      "/sign-in"
    )
  }
  const teachersSupport = () => {
    props.history.push(
      "/Teacher Support"
    )
  }
  const studentsSupport = () => {
    props.history.push(
      "/Student Support"
    )
  }
  const parentsSupport = () => {
    props.history.push(
      "/Parent Support"
    )
  }
  return (
    <div className={classes.root}>
      <AppBar position="sticky">

        {/* <ElevationScroll {...props}> */}
        <AppBar>
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleClick}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Impru Support
            </Typography>
            <IconButton>
              <Avatar alt="Impru" src={logo} />
            </IconButton>

          </Toolbar>
        </AppBar>
        {/* </ElevationScroll> */}
        <Toolbar />
        {/* <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={goToInstitutes}>Institutes</MenuItem>
          <MenuItem onClick={gotoProfile}>Profile</MenuItem>
          <MenuItem onClick={() => {
            dispatch(logout())
          }}>Logout</MenuItem>
        </Menu> */}
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {auth.isAuthenticated ? <div>
            <StyledMenuItem onClick={teachersSupport}>
              <ListItemIcon>
                <PeopleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Teachers" />
            </StyledMenuItem>
            <StyledMenuItem onClick={studentsSupport}>
              <ListItemIcon>
                <PeopleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Students" />
            </StyledMenuItem>
            <StyledMenuItem onClick={parentsSupport}>
              <ListItemIcon>
                <PeopleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Parents" />
            </StyledMenuItem>
            <StyledMenuItem onClick={gotoProfile}>
              <ListItemIcon>
                <Person fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </StyledMenuItem>
            <StyledMenuItem onClick={() => {
              dispatch(logout())
            }}>
              <ListItemIcon >
                <ExitToApp fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </StyledMenuItem></div> : <div>
            <StyledMenuItem onClick={goToLogin}>
              <ListItemIcon >
                <ExitToApp fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Login" />
            </StyledMenuItem></div>}
        </StyledMenu>

      </AppBar>
    </div>
  );
}
export default withRouter(CustomAppBar)