import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import instituteImage from "../assets/images/institute.jpg";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    minWidth: 300,
    paddingLeft:0
  },
  media: {
    height: 120,
    width: 120,
    borderRadius: 60,
  },
}));

const InstituteCardItem = (props) => {
  const classes = useStyles();
  console.log(props.institute);
  return (
    <Card className={classes.root} key={props.institute.registrationId}>
      <div style={{ justifyContent: "center", display: "flex", marginTop: 8 }}>
        <CardMedia
          className={classes.media}
          image={props.institute.imageUrl?props.institute.imageUrl:instituteImage}
          title="Contemplative Reptile"
        />
      </div>
      <CardContent>
        <Typography
          style={{
            textAlign: "center",
          }}
          gutterBottom
          variant="subtitle1"
          component="h4"
        >
          {props.institute.name}
        </Typography>
        <Typography
          style={{
            textAlign: "center",
          }}
          gutterBottom
          variant="subtitle2"
          component="p"
        >
          {props.institute.instituteNumber}
        </Typography>
      </CardContent>

      <CardActions
        style={{
          justifyContent: "space-around",
          backgroundColor: "whitesmoke",
        }}
      >
        <Button
          size="small"
          color="secondary"
          onClick={props.onClickToUpdateContent}
        >
          Content
        </Button>
        <Button
          size="small"
          color="secondary"
          onClick={props.onClickGetInstituteDetail}
        >
          Details
        </Button>
        {/* <Button
          size="small"
          color="secondary"
          onClick={props.onClickGetInstituteGroups}
        >
          Groups
        </Button> */}
      </CardActions>
    </Card>
  );
};
export default InstituteCardItem;
